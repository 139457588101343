import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=4b018cec"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LangSwitch: require('/vercel/path0/frontend/components/ui/LangSwitch.vue').default,Button: require('/vercel/path0/frontend/components/ui/Button.vue').default,Header: require('/vercel/path0/frontend/components/ui/Header.vue').default})
