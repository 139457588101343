import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c45ff820 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _6c5b9c43 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _84f04296 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _004c7edd = () => interopDefault(import('../pages/journal/index.vue' /* webpackChunkName: "pages/journal/index" */))
const _2d6618b0 = () => interopDefault(import('../pages/lab.vue' /* webpackChunkName: "pages/lab" */))
const _0e98d9e7 = () => interopDefault(import('../pages/projects.vue' /* webpackChunkName: "pages/projects" */))
const _57b4e14a = () => interopDefault(import('../pages/ui-kit.vue' /* webpackChunkName: "pages/ui-kit" */))
const _02d444d6 = () => interopDefault(import('../pages/journal/_slug.vue' /* webpackChunkName: "pages/journal/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _c45ff820,
    name: "about___cs"
  }, {
    path: "/contact",
    component: _6c5b9c43,
    name: "contact___cs"
  }, {
    path: "/en",
    component: _84f04296,
    name: "index___en"
  }, {
    path: "/journal",
    component: _004c7edd,
    name: "journal___cs"
  }, {
    path: "/lab",
    component: _2d6618b0,
    name: "lab___cs"
  }, {
    path: "/projects",
    component: _0e98d9e7,
    name: "projects___cs"
  }, {
    path: "/ui-kit",
    component: _57b4e14a,
    name: "ui-kit___cs"
  }, {
    path: "/en/about",
    component: _c45ff820,
    name: "about___en"
  }, {
    path: "/en/contact",
    component: _6c5b9c43,
    name: "contact___en"
  }, {
    path: "/en/journal",
    component: _004c7edd,
    name: "journal___en"
  }, {
    path: "/en/lab",
    component: _2d6618b0,
    name: "lab___en"
  }, {
    path: "/en/projects",
    component: _0e98d9e7,
    name: "projects___en"
  }, {
    path: "/en/ui-kit",
    component: _57b4e14a,
    name: "ui-kit___en"
  }, {
    path: "/",
    component: _84f04296,
    name: "index___cs"
  }, {
    path: "/en/journal/:slug",
    component: _02d444d6,
    name: "journal-slug___en"
  }, {
    path: "/journal/:slug",
    component: _02d444d6,
    name: "journal-slug___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
